<template>
  <div>
    <confirm-delete
      ref="modal"
      :msg="deleteMsg"
      :title="titleMsg"
      @on-submit="deleteCategory"
    />

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            /> -->
          </b-col>

          <!-- Search -->
          <b-col md="6">
            <div
              v-if="
                checkLoginRole() === RoleEnum.SuperAdmin
                  ? true
                  : checkAccess.Add
              "
              class="d-flex align-items-center justify-content-end flex-wrap"
            >
              <!-- <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              /> -->
              <b-button
                class="mb-2"
                variant="primary"
                @click="$router.push('/faq/list')"
              >
                <span class="text-nowrap">Back to FAQ</span>
              </b-button>

              <b-button class="mb-2 ml-2" variant="primary" @click="$router.push('add')">
                <span class="text-nowrap">Add Category</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Question -->.
        <template #cell(category)="data">
          {{ data.item.name }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="
                checkLoginRole() === RoleEnum.SuperAdmin
                  ? true
                  : checkAccess.Edit
              "
              :to="{ name: 'category-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="
                checkLoginRole() === RoleEnum.SuperAdmin
                  ? true
                  : checkAccess.delete
              "
              @click="openModal(data.item.id, data.item.name)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing
              {{ totalCategories >= 1 ? startIndex + 1 : startIndex }} to
              {{ endIndex > totalCategories ? totalCategories : endIndex }} of
              {{ totalCategories }} entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCategories"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import {
  resolveUserRoleIcon,
  resolveUserRoleVariant,
  resolveUserStatusVariant,
  resolveUserRoleName,
  resolveUserStatusName,
  perPageOptions,
  perPage,
  currentPage,
  RoleEnum,
  statusFilter,
  resConditionCheck,
  deleteConfirmMsg
} from "@/helpers/constant";
import accessRightCheck from '@/helpers/accessRightCheck';
import checkLoginRole from "@/helpers/checkLoginRole";
import faqService from "@/services/faq/faq.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import errorResponseHandler from "@/services/errorHandler";
import ConfirmDelete from "../../confirm-delete/ConfirmDelete.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    ConfirmDelete
  },

  data() {
    return {
      searchQuery: "",
      sortBy: "",
      totalCategories: 0,
      isSortDirDesc: true,
      avatarText,
      items: [],
      startIndex: 0,
      endIndex: 0,
      readMoreActivated: false,
      tableColumns: [{ key: "category" }, { key: "actions" }],
      currentPage,
      perPage,
      perPageOptions,
      resolveUserRoleIcon,
      resolveUserRoleVariant,
      resolveUserStatusVariant,
      resolveUserRoleName,
      resolveUserStatusName,
      resConditionCheck,
      statusFilter,
      deleteMsg: "",
      titleMsg: "",
      userId: "",
      toggleModal: false,
      filterList: [],
      first_name: "",
      last_name: "",
      email: "",
      signinAsAdmin: false,
      status_filter: null,
      checkAccess: {},
      checkLoginRole,
      RoleEnum,
      disableMsg: "",
      faqId: "",
      deleteCategoryId: "",
      status2: ""
    };
  },

  mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name);
    this.getCategoryListing();
  },

  methods: {
    getCategoryListing() {
      faqService
        .getCategoryListing()
        .then(res => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.items = res.data.data;
            this.totalCategories = res.data.count;
            this.startIndex = this.currentPage * this.perPage - this.perPage;
            this.endIndex = this.startIndex + this.perPage;
          }
        })
        .catch(error => {
          const errorData = errorResponseHandler(error);
          if (error.response.status === 404) {
            this.items = [];
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          }
        });
    },

    openModal(id, name) {
      this.titleMsg = deleteConfirmMsg;
      this.deleteMsg = `Are you sure you want to delete ${name} category?`;
      this.deleteCategoryId = id;
      this.$refs.modal.show();
    },

    deleteCategory(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.modal.loading = true;
      if (this.deleteCategoryId) {
        faqService
          .deleteCategory(this.deleteCategoryId)
          .then(res => {
            if (resConditionCheck(res.status)) {
              this.$refs.modal.loading = false;
              this.$refs.modal.toggleModal = false;
              this.items = this.items.filter(
                item => item.id !== this.deleteCategoryId
              );
              if (this.items.length === 0 && this.currentPage !== 1) {
                this.currentPage -= 1;
                this.getCategoryListing();
              } else {
                this.getCategoryListing();
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success"
                }
              });
            }
          })
          .catch(error => {
            this.$refs.modal.loading = false;
            this.$refs.modal.toggleModal = false;
            const errorData = errorResponseHandler(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          });
      }
    }
  }
};
</script>
